
import { PropType } from "vue";
import { Vue, Options } from "vue-class-component";

@Options({
  name: "ModalV2",
  props: {
    title: {
      default: "",
      type: String
    },
    onClose: {
      default: Function as PropType<() => void>,
      type: Function
    },
    showFooter: {
      default: true,
      type: Boolean
    },
    width: {
      default: "w-8/12",
      type: String
    },
    isCustomTitle: {
      default: false,
      type: Boolean
    },
    showBorderTitle: {
      default: true,
      type: Boolean
    }
  }
})
export default class extends Vue {}
